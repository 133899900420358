<script setup>
import {useBaseStore} from "~/stores/base";
import ModuleLogin from "~/components/login/ModuleLogin.vue";

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
</script>

<template>
  <div class="login-content-mobile" v-if="isMobile">
    <div class="glass">
      <module-login/>
    </div>
  </div>
  <div class="login-content-desktop" v-else>
    <div class="glass">
      <module-login/>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.login-content-mobile {
  background: white center center no-repeat;
  background-size: cover;

  .glass {
    background: rgba(255, 255, 255, 0.1); /* 半透明白色背景 */
    backdrop-filter: blur(10px);
    padding: 40px 0;
    display: flex;
    justify-content: center;
  }
}

.login-content-desktop {
  min-width: $content-1280-width;
  min-height: calc(100vh - $desktop-header-height - $desktop-footer-height);
  background: white center center no-repeat;
  background-size: cover;

  .glass {
    background: rgba(255, 255, 255, 0.1); /* 半透明白色背景 */
    backdrop-filter: blur(10px);
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
