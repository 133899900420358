<script setup>
import {useBaseStore} from "~/stores/base";
import ModuleInput from "~/components/ModuleInput.vue";
import {
  EMAIL_ERROR_TIPS_MAP,
  PASSWORD_ERROR_TIPS_MAP,
  PATH_RESET_PASSWORD,
  PATH_REGISTER,
  URL_HOME
} from "~/utils/constants";
import {emailValid} from "~/utils/common";
import {userLogin} from "~/api/api.user";
import {config} from "~/utils/config";
import {Loading} from "@element-plus/icons-vue";
import {ELEMENT_LABEL, ELEMENT_TITLE, ELEMENT_TYPE} from "~/utils/smartlook";
import FirebaseGoogle from "~/components/login/third-party/FirebaseGoogle.vue";
import FirebaseApple from "~/components/login/third-party/FirebaseApple.vue";
import FirebaseFacebook from "~/components/login/third-party/FirebaseFacebook.vue";

const route = useRoute()
const router = useRouter()

/** data **/
const email = ref(route.query.email)
const emailErrorTips = ref("")
const emailError = ref(false)

const password = ref("")
const passwordError = ref(false)
const passwordErrorTips = ref("")

const isLoading = ref(false)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const btnLoginDisabled = computed(() => {
  return !email.value || !password.value || emailError.value || passwordError.value || isLoading.value
})

/** methods **/
const handleEmailBlur = () => {
  // reset
  emailError.value = false
  emailErrorTips.value = ""
  // 必填校验
  if (!email.value) {
    emailError.value = true
    emailErrorTips.value = EMAIL_ERROR_TIPS_MAP.REQUIRED
    return
  }
  // 合法校验
  const valid = emailValid(email.value)
  if (!valid) {
    emailError.value = true
    emailErrorTips.value = EMAIL_ERROR_TIPS_MAP.FORMAT_ERROR
  }
}

const handlePasswordBlur = () => {
  // reset
  passwordError.value = false
  passwordErrorTips.value = ""

  // 必填校验
  if (!password.value) {
    passwordError.value = true
    passwordErrorTips.value += PASSWORD_ERROR_TIPS_MAP.REQUIRED
  }
}

const handleLoginClick = async () => {
  if (!email.value || !password.value) return;
  if (emailError.value || passwordError.value) return;
  if (isLoading.value) return;
  try {
    isLoading.value = true
    await userLogin({email: email.value, password: password.value})
    isLoading.value = false
    let redirect_uri = route.query.redirect_uri || URL_HOME;
    if (!redirect_uri.includes("http")) redirect_uri = import.meta.env.VITE_ORIGIN_WEBSITE + redirect_uri;
    window.location.href = redirect_uri;
  } catch (e) {
    isLoading.value = false
    switch (e.code) {
      case config.BUSINESS_CODE.EMAIL_NOT_EXIST:
        emailError.value = true;
        emailErrorTips.value = e.message.content;
        break;
      case config.BUSINESS_CODE.DATA_IS_NOT_FOUND:
        emailError.value = true;
        emailErrorTips.value = e.message.content;
        break;
      case config.BUSINESS_CODE.EMAIL_OR_PASSWORD_ERROR:
        emailError.value = true;
        emailErrorTips.value = e.message.content;
        break;
    }
  }
}
</script>

<template>
  <div class="module-login-mobile" v-if="isMobile">
    <div class="subtitle">WELCOME BACK</div>
    <div class="title">LOGIN</div>
    <div class="cell">
      <module-input
        type="text"
        v-model="email"
        title="Email"
        name="email"
        :error-tips="emailErrorTips"
        :error="emailError"
        @blur="handleEmailBlur"
      />
    </div>
    <div class="cell">
      <module-input
        type="password"
        v-model="password"
        title="Password"
        name="password"
        :error-tips="passwordErrorTips"
        :error="passwordError"
        @blur="handlePasswordBlur"
      />
    </div>
    <div class="tips">
      Forgot your password? Click
      <nuxt-link
        class="bold"
        :to="PATH_RESET_PASSWORD"
        element-click-observe
        :element-type="ELEMENT_TYPE.LINK"
        :element-title="ELEMENT_TITLE.RESET_PASSWORD"
        :element-label="ELEMENT_LABEL.LOGIN"
      >
        here
      </nuxt-link>
    </div>
    <div
      class="btn-primary btn-login"
      :class="{'btn-disabled': btnLoginDisabled}"
      @click="handleLoginClick"
      element-click-observe
      :element-type="ELEMENT_TYPE.BUTTON"
      :element-title="ELEMENT_TITLE.LOGIN"
      :element-label="ELEMENT_LABEL.LOGIN"
    >
      <el-icon v-if="isLoading" class="is-loading" color="white" size="16" style="margin-right: 8px">
        <Loading/>
      </el-icon>
      LOGIN
    </div>
    <div class="third-party">
      <div class="or">or</div>
      <firebase-google/>
      <firebase-apple/>
      <firebase-facebook/>
    </div>
    <div class="divider"/>
    <div class="bottom">
      <div class="bottom-left">No account yet?</div>
      <nuxt-link
        class="btn-primary bottom-right"
        :to="PATH_REGISTER"
        element-click-observe
        :element-type="ELEMENT_TYPE.BUTTON"
        :element-title="ELEMENT_TITLE.JOIN_NOW"
        :element-label="ELEMENT_LABEL.LOGIN"
      >
        <img src="@/assets/img/icon-flash.svg" alt="flash">
        join now
      </nuxt-link>
    </div>
  </div>
  <div class="module-login-desktop" v-else>
    <div class="subtitle">WELCOME BACK</div>
    <div class="title">LOGIN</div>
    <div class="cell">
      <module-input
        type="text"
        v-model="email"
        title="Email"
        name="email"
        :error-tips="emailErrorTips"
        :error="emailError"
        @blur="handleEmailBlur"
      />
    </div>
    <div class="cell">
      <module-input
        type="password"
        v-model="password"
        title="Password"
        name="password"
        :error-tips="passwordErrorTips"
        :error="passwordError"
        @blur="handlePasswordBlur"
      />
    </div>
    <div class="tips">
      Forgot your password? Click
      <nuxt-link
        class="bold"
        :to="PATH_RESET_PASSWORD"
        element-click-observe
        :element-type="ELEMENT_TYPE.LINK"
        :element-title="ELEMENT_TITLE.RESET_PASSWORD"
        :element-label="ELEMENT_LABEL.LOGIN"
      >
        here
      </nuxt-link>
    </div>
    <div
      class="btn-primary btn-login"
      :class="{'btn-disabled': btnLoginDisabled}"
      @click="handleLoginClick"
      element-click-observe
      :element-type="ELEMENT_TYPE.BUTTON"
      :element-title="ELEMENT_TITLE.LOGIN"
      :element-label="ELEMENT_LABEL.LOGIN"
    >
      <el-icon v-if="isLoading" class="is-loading" color="white" size="16" style="margin-right: 8px">
        <Loading/>
      </el-icon>
      LOGIN
    </div>
    <div class="third-party">
      <div class="or">or</div>
      <firebase-google/>
      <firebase-apple/>
      <firebase-facebook/>
    </div>
    <div class="divider"/>
    <div class="bottom">
      <div class="bottom-left">No account yet?</div>
      <nuxt-link
        class="btn-primary bottom-right"
        :to="PATH_REGISTER"
        element-click-observe
        :element-type="ELEMENT_TYPE.BUTTON"
        :element-title="ELEMENT_TITLE.JOIN_NOW"
        :element-label="ELEMENT_LABEL.LOGIN"
      >
        <img src="@/assets/img/icon-flash.svg" alt="flash">
        join now
      </nuxt-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-login-mobile {
  width: 375px;
  background-color: white;
  padding: 24px 24.5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .subtitle {
    //styleName: Eyebrow/medium;
    font-family: "TWK Lausanne";
    font-weight: 650;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.004em;
    text-align: left;
  }

  .title {
    //styleName: Headline/large;
    font-family: Alternate Gothic No3 D;
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -0.01em;
    text-align: left;
    margin-top: 9px;
    margin-bottom: 24px;
  }

  .cell {
    width: 100%;
    margin-bottom: 15px;
  }

  .tips {
    width: 100%;
    font-family: TWK Lausanne;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;

    .bold {
      font-family: "TWK Lausanne";
      font-weight: 650;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      text-decoration-line: underline;
    }
  }

  .btn-login {
    margin-top: 40px;
    width: 100%;
  }

  .third-party {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .or {
      text-align: center;
      font-family: "TWK Lausanne";
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px; /* 166.667% */
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $color-gray-300;
    margin-top: 40px;
  }

  .bottom {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    .bottom-left {
      //styleName: Text sm/Regular;
      font-family: TWK Lausanne;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      text-align: left;
    }

    .bottom-right {
      width: 177px;
      padding: 12px 0 10px 0;

      img {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
    }
  }
}

.module-login-desktop {
  width: 375px;
  background-color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .subtitle {
    //styleName: Eyebrow/medium;
    font-family: "TWK Lausanne";
    font-weight: 650;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.004em;
    text-align: left;
  }

  .title {
    //styleName: Headline/large;
    font-family: Alternate Gothic No3 D;
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -0.01em;
    text-align: left;
    margin-top: 9px;
    margin-bottom: 24px;
  }

  .cell {
    width: 100%;
    margin-bottom: 15px;
  }

  .tips {
    width: 100%;
    font-family: TWK Lausanne;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;

    .bold {
    font-family: "TWK Lausanne";
    font-weight: 650;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      text-decoration-line: underline;
    }
  }

  .btn-login {
    margin-top: 40px;
    width: 100%;
  }

  .third-party {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .or {
      text-align: center;
      font-family: "TWK Lausanne";
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px; /* 166.667% */
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: $color-gray-300;
    margin-top: 40px;
  }

  .bottom {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    .bottom-left {
      //styleName: Text sm/Regular;
      font-family: TWK Lausanne;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      text-align: left;
    }

    .bottom-right {
      width: 177px;
      padding: 12px 0 10px 0;

      img {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
    }
  }
}
</style>
